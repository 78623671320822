import loadable from '@loadable/component';
// eslint-disable-next-line import/no-extraneous-dependencies
import config from 'config'; // this package is being loaded via webpack
import React from 'react';
import { render } from 'react-dom';
import { Route } from 'react-router-dom';

import props from '@Hub/defaults';
import AppTheme from '@Hub/Theme';

import AppContext from '@core/context';
import { InitializeProjectStore } from '@core/store';
import DebugRouter from '@core/utils/DebugRouter';

import HARContext from '@routes/Reference/context/HARContext';

const ReferenceTestBed = loadable(() => import('@routes/Reference/TestBed'));
const ReferencePage = loadable(() => import('@routes/Reference'));

const root = document.getElementById('root');
if (root) {
  /**
   * Because of the way our Reference routing layers work they need to run on a `/reference` route.
   * Unfortunately folks that come to https://preview.readme.io don't come to
   * https://preview.readme.io/reference so we need to immediately redirect them otherwise all
   * reference routing will fail and they'll only see a blank screen.
   */
  if (!window.location.pathname.includes('/reference')) {
    window.location.pathname = '/reference';
  }

  // We don't use Sentry in the playground environment
  delete config.sentry;

  // ReferenceTestBed should **only** have one child: the Reference route
  render(
    <DebugRouter basename={props.baseUrl}>
      <InitializeProjectStore data={props.context.projectStore.data}>
        <AppContext apiBaseUrl={props.apiBaseUrl} baseUrl={props.baseUrl} config={config} context={props.context}>
          <AppTheme>
            <HARContext>
              <Route path="/reference/:slug?">
                <ReferenceTestBed {...props}>
                  <ReferencePage {...props} />
                </ReferenceTestBed>
              </Route>
            </HARContext>
          </AppTheme>
        </AppContext>
      </InitializeProjectStore>
    </DebugRouter>,
    root,
  );
}
